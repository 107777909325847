<template>
  <default-page class="home-page">
    <div class="home-page__block">
      <h1 class="home-page__h1">Немного о себе</h1>
      <div class="home-page__section">
        <div>
          <p>
            Тут я напишу несколько слов о себе, своем опыте, том как, где, с кем и кем работал и все такое.
            Тут я напишу несколько слов о себе, своем опыте, том как, где, с кем и кем работал и все такое.
            Тут я напишу несколько слов о себе, своем опыте, том как, где, с кем и кем работал и все такое.
            Тут я напишу несколько слов о себе, своем опыте, том как, где, с кем и кем работал и все такое.
            Тут я напишу несколько слов о себе, своем опыте, том как, где, с кем и кем работал и все такое.
          </p>
          <p>
            Тут я напишу несколько слов о себе, своем опыте, том как, где, с кем и кем работал и все такое.
            Тут я напишу несколько слов о себе, своем опыте, том как, где, с кем и кем работал и все такое.
            Тут я напишу несколько слов о себе, своем опыте, том как, где, с кем и кем работал и все такое.
            Тут я напишу несколько слов о себе, своем опыте, том как, где, с кем и кем работал и все такое.
            Тут я напишу несколько слов о себе, своем опыте, том как, где, с кем и кем работал и все такое.
          </p>
        </div>
        <mg-image :src="require('@/assets/photo.png')" :width="200" class="home-page__photo" />
      </div>
    </div>
    <div class="home-page__block">
      <h1 class="home-page__h1">MG.Soft: компания или личный бренд?</h1>
      <div class="home-page__section">
        <mg-image :src="require('@/assets/software.png')" :width="200" class="home-page__photo" />
        <div>
          <p>
            Тут я напишу несколько слов о себе, своем опыте, том как, где, с кем и кем работал и все такое.
            Тут я напишу несколько слов о себе, своем опыте, том как, где, с кем и кем работал и все такое.
            Тут я напишу несколько слов о себе, своем опыте, том как, где, с кем и кем работал и все такое.
            Тут я напишу несколько слов о себе, своем опыте, том как, где, с кем и кем работал и все такое.
            Тут я напишу несколько слов о себе, своем опыте, том как, где, с кем и кем работал и все такое.
          </p>
          <p>
            Тут я напишу несколько слов о себе, своем опыте, том как, где, с кем и кем работал и все такое.
            Тут я напишу несколько слов о себе, своем опыте, том как, где, с кем и кем работал и все такое.
            Тут я напишу несколько слов о себе, своем опыте, том как, где, с кем и кем работал и все такое.
            Тут я напишу несколько слов о себе, своем опыте, том как, где, с кем и кем работал и все такое.
            Тут я напишу несколько слов о себе, своем опыте, том как, где, с кем и кем работал и все такое.
          </p>
          <p>
            Тут я напишу несколько слов о себе, своем опыте, том как, где, с кем и кем работал и все такое.
            Тут я напишу несколько слов о себе, своем опыте, том как, где, с кем и кем работал и все такое.
            Тут я напишу несколько слов о себе, своем опыте, том как, где, с кем и кем работал и все такое.
            Тут я напишу несколько слов о себе, своем опыте, том как, где, с кем и кем работал и все такое.
            Тут я напишу несколько слов о себе, своем опыте, том как, где, с кем и кем работал и все такое.
          </p>
        </div>
      </div>
    </div>
    <div class="home-page__block">
      <h1 class="home-page__h1" style="text-align: center; padding-bottom: 20px;">&star; &nbsp; &nbsp;  &star; &nbsp; &nbsp; &star; &nbsp; &nbsp;  &star; </h1>
      <div class="home-page__section home-page__section--clients">
        <a href="http://sporra.ru/" target="_blank">
          <img src="./../../assets/clients/spora.png" class="home-page__client-logo" />
        </a>
        <a href="https://vilazh.ru/" target="_blank">
          <img src="./../../assets/clients/vilazh.png" class="home-page__client-logo" />
        </a>
        <a href="https://enera-group.com/" target="_blank">
          <img src="./../../assets/clients/enera-group.png" class="home-page__client-logo" />
        </a>
        <a href="http://autocombinat.ru/" target="_blank">
          <img src="./../../assets/clients/auto-2.png" class="home-page__client-logo" />
        </a>
        <a href="https://nefrosovet.ru/" target="_blank">
          <img src="./../../assets/clients/nefrosovet.png" class="home-page__client-logo" />
        </a>
        <a href="https://ipotech.su/" target="_blank">
          <img src="./../../assets/clients/ipotech.png" class="home-page__client-logo home-page__client-logo--small" />
        </a>
        <a href="https://onlinesklad.ru/" target="_blank">
          <img src="./../../assets/clients/onlinesklad.png" class="home-page__client-logo" />
        </a>
      </div>
      <div class="home-page__section home-page__section--clients">
        <a href="https://cleaning911.ru" target="_blank">
          <img src="./../../assets/clients/cleaning911.jpg" class="home-page__client-logo home-page__client-logo--small" />
        </a>
        <a href="https://omkservice.ru/" target="_blank">
          <img src="./../../assets/clients/omk.png" class="home-page__client-logo home-page__client-logo--small" style="margin-top: -18px" />
        </a>
      </div>
    </div>
    <div class="home-page__block">
      <h1 class="home-page__h1">Workflow</h1>
      <div class="home-page__section">
        <div>
          <p>
            Тут я напишу несколько слов о себе, своем опыте, том как, где, с кем и кем работал и все такое.
            Тут я напишу несколько слов о себе, своем опыте, том как, где, с кем и кем работал и все такое.
            Тут я напишу несколько слов о себе, своем опыте, том как, где, с кем и кем работал и все такое.
            Тут я напишу несколько слов о себе, своем опыте, том как, где, с кем и кем работал и все такое.
            Тут я напишу несколько слов о себе, своем опыте, том как, где, с кем и кем работал и все такое.
          </p>
          <p>
            Тут я напишу несколько слов о себе, своем опыте, том как, где, с кем и кем работал и все такое.
            Тут я напишу несколько слов о себе, своем опыте, том как, где, с кем и кем работал и все такое.
            Тут я напишу несколько слов о себе, своем опыте, том как, где, с кем и кем работал и все такое.
            Тут я напишу несколько слов о себе, своем опыте, том как, где, с кем и кем работал и все такое.
            Тут я напишу несколько слов о себе, своем опыте, том как, где, с кем и кем работал и все такое.
          </p>
        </div>
        <img src="./../../assets/workflow.png" class="home-page__photo home-page__photo--wide" />
      </div>
    </div>
  </default-page>
</template>

<script>
import DefaultPage from "@/components/Page";
import MgImage from "@/components/MGImage";
export default {
  name: "HomePage",
  components: {MgImage, DefaultPage }
}
</script>

<style scoped lang="scss" src="./style.scss" />
