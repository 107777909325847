<template>
  <div class="mg-logo">
    <span class="mg-logo__logo">MG.Soft</span>
    <span class="mg-logo__by">by Martynov Dmitry</span>
  </div>
</template>

<script>
export default {
  name: "MgLogo"
}
</script>

<style scoped lang="scss">
  .mg-logo {
    display: inline-flex;
    flex-direction: column;
    text-align: center;

    &__logo {
      font-size: 34px;
      font-weight: bold;
      font-family: Brush Script MT, Monotype Corsiva;
    }

    &__by {
      font-family: monospace;
      font-size: 10px;
      color: dimgray;
    }
  }
</style>
