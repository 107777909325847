<template>
  <div class="mg-image">
    <img v-show="loaded" class="mg-image__img" :style="{ width: `${width}px` }" :src="src" @load="handleImageLoaded" />
    <div v-if="!loaded" :style="{ width: `${width}px`, height: `${width}px` }" class="mg-image__skeleton">
      <div v-if="showLoadingCircle" class="mg-image__skeleton__circle"></div>
      <div v-if="showLoadingLogo" class="mg-image__skeleton__text">MG.Soft</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MgImage",
  props: {
    src: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 100
    },
    showLoadingCircle: {
      type: Boolean,
      default: true
    },
    showLoadingLogo: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loaded: false
    }
  },
  methods: {
    handleImageLoaded () {
      this.loaded = true
    }
  }
}
</script>

<style scoped lang="scss">
.mg-image {
  &__img {
    width: 100%;
    height: auto;
  }

  &__skeleton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 30px 10px;

    &__circle {
      width: 50%;
      height: 50%;
      -webkit-filter: blur(15px);
      -moz-filter: blur(15px);
      border-radius: 50%;
      margin: -15px;
      background: lightgray;
      margin-bottom: 20px;
      background: linear-gradient(90deg, #E6E6E6, #D8D8D8, #BDBDBD, #A4A4A4, #BDBDBD, #D8D8D8, #E6E6E6);
      background-size: 300% 300%;
      animation: pulse-black 2s ease infinite;
    }

    &__text {
      -webkit-filter: blur(1px);
      -moz-filter: blur(1px);
      width: 60%;
      background: lightgray;
      margin-top: 20px;
      background: linear-gradient(90deg, #fff, #FAFAFA, #F2F2F2, #E6E6E6, #D8D8D8, #E6E6E6, #F2F2F2, #FAFAFA, #fff);
      background-size: 400% 300%;
      padding: 8px;
      animation: gradient 2s ease infinite;
    }
  }
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes pulse-black {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

</style>
