<template>
  <div class="top-menu">
    <ul class="top-menu__ul">
      <li
          v-for="route in routes"
          :key="`route_${route.name}`"
          :class="{ 'selected': isRouteSelected(route.name) }"
          @click="handleClick(route.name)">{{ route.meta.title }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'TopMenu',
  computed: {
    routes: {
      get() {
        return this.$router.getRoutes() || []
      }
    }
  },
  mounted() {
  },
  methods: {
    handleClick (routeName) {
      if (this.$route.name !== routeName) {
        this.$router.push({ name: routeName })
      }
    },
    isRouteSelected (routeName) {
      return this.$route?.name === routeName
    }
  }
}
</script>

<style scoped lang="scss" src="./style.scss" />
