<template>
  <div class="app">
    <div class="header">
      <mg-logo />
      <top-menu class="app__menu" />
    </div>
    <router-view />
  </div>
</template>

<script>
import TopMenu from "@/components/TopMenu";
import MgLogo from "@/components/MGLogo";
export default {
  name: 'App',
  components: {MgLogo, TopMenu }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 30px;
}
.app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 60px;
  width: 777px;
  margin: auto;

  &__menu {
    margin-top: 30px;
  }

  .header {
    position: sticky;
    padding: 20px 0;
    top: 0px;
    background: white;
  }
}
</style>
