<template>
  <default-page class="contacts-page">
    <span>email: info@writecode.art</span>
    <span>phone: +7 (977) 800-77-07</span>
  </default-page>
</template>

<script>
import DefaultPage from "@/components/Page";
export default {
  name: "ContactsPage",
  components: {DefaultPage}
}
</script>

<style scoped lang="scss" src="./style.scss" />
