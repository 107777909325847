<template>
  <div class="default-page">
       <slot />
  </div>
</template>

<script>
export default {
  name: "DefaultPage"
}
</script>

<style scoped>
  .default-page {
    margin-top: 30px;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    justify-content: flex-start;
    text-align: left;
  }
</style>
