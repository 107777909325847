import { createWebHistory, createRouter } from "vue-router";
import ContactsPage from "./pages/contacts"
import HomePage from "./pages/home"

const routes = [
  {
    path: "/",
    name: "home",
    component: HomePage,
    meta: {
      title: "Home"
    }
  },
  {
    path: "/projects",
    name: "projects",
    meta: {
      title: "Проекты"
    }
  },
  {
    path: "/technologies",
    name: "technologies",
    meta: {
      title: "Технологии"
    }
  },
  {
    path: "/collaboration",
    name: "collaboration",
    meta: {
      title: "Сотрудничество"
    }
  },
  {
    path: "/jobs",
    name: "jobs",
    meta: {
      title: "Вакансии"
    }
  },
  {
    path: "/news",
    name: "news",
    meta: {
      title: "Новости"
    }
  },
  {
    path: "/reviews",
    name: "reviews",
    meta: {
      title: "Отзывы"
    }
  },
  {
    path: "/contacts",
    name: "contacts",
    component: ContactsPage,
    meta: {
      title: "Контакты"
    }
  },
]

const router = createRouter({
  history: createWebHistory(),
  mode: "hash",
  base: process.env.BASE_URL,
  routes
})

export default router
