import { createStore } from 'vuex'

export default createStore({
	state() {
		return {
			lang: "ru"
		}
	},
	modules: {
	}
})
